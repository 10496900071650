import { memo, useRef } from 'react';
import { FeaturedActivitiesPreview } from './featuredActivitiesPreview/FeaturedActivitiesPreview';
import * as S from './FeaturedActivities.styles';
import { SliderDots } from '@/common/ui';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { IRecommendsItem } from './featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { FeaturedActivitiesSkeleton } from './FeaturedActivitiesSkeleton/FeaturedActivitiesSkeleton';
import { useAttractionsContext } from '@/common/app/contexts';

const FIRST_TWO_PAGES = 8;

export type FeaturedActivitiesProps = {
    activities: IActivity[] | IRecommendsItem[];
    title?: string;
    subTitle?: string;
    maxWidthForTitle?: string;
    isFromSearch?: boolean;
    item_list_name: string;
    item_list_id: string;
};

export const FeaturedActivitiesContainer = ({
    activities,
    title,
    subTitle,
    maxWidthForTitle,
    isFromSearch,
    item_list_name,
    item_list_id,
}: FeaturedActivitiesProps) => {
    const mainTitle = title || '';
    const isFeaturedList = mainTitle.includes('Featured');
    const scrollRef = useRef<HTMLDivElement>(null);
    const { isRecommendsLoading } = useAttractionsContext();

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
        dotActive,
    } = useCarousel({ ref: scrollRef, countItems: activities.length });

    if (!activities.length) {
        return null;
    }

    return (
        <S.Wrapper>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    <S.Header>
                        <S.LeftPartHeader>
                            <S.Title style={{ maxWidth: maxWidthForTitle || 'none' }}>
                                {mainTitle}
                            </S.Title>
                            {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
                        </S.LeftPartHeader>
                    </S.Header>
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {activities.map((slide, index) => {
                                    if (isRecommendsLoading) {
                                        return <FeaturedActivitiesSkeleton key={slide.id} />;
                                    }

                                    return (
                                        <FeaturedActivitiesPreview
                                            {...slide}
                                            numberOfItems={activities.length}
                                            position={index}
                                            isFeaturedList={isFeaturedList}
                                            key={slide.id}
                                            eager={index < FIRST_TWO_PAGES}
                                            listName={mainTitle}
                                            isFromSearch={isFromSearch}
                                            item_list_id={item_list_id}
                                            item_list_name={item_list_name}
                                            promotion_name={mainTitle}
                                        />
                                    );
                                })}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && withDot && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && withDot && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                    {!!withDot && (
                        <S.DotsSlider>
                            {[...Array(activities.length).keys()].map((el) => {
                                return (
                                    <SliderDots isActive={el === Math.ceil(dotActive)} key={el} />
                                );
                            })}
                        </S.DotsSlider>
                    )}
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};

export const FeaturedActivities = memo(FeaturedActivitiesContainer);
